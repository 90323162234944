import React from "react";
import main_image from "./ctrlchef.jpg";
import "./App.css";

declare global {
  interface Window {
    dataLayer: any;
  }
}

function App() {
  const myClick = () => {
    window.dataLayer = window.dataLayer || [];
    const config = {
      event: "gaEvent",
      eventCategory: "espaco-cliente",
      eventAction: "mudar-dados-pessoais",
      eventLabel: "sucesso",
    };
    console.log(config);
    window.dataLayer.push(config);
  };

  return (
    <div className="App">
      <div onClick={myClick}>
        <img src={main_image} className="main-image" alt="logo" />
      </div>
      <p>2023 ctrlchef.com</p>
    </div>
  );
}

export default App;
